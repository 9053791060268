const state = reactive({
  routerHistory: "",
});
const setRouterHistory = (route: string) => {
  state.routerHistory = route;
};
const useRouterHistory = () => {
  const routerHistory = computed(() => state.routerHistory);

  return {
    routerHistory,
    setRouterHistory,
  };
};

export default useRouterHistory;
